import Moment from "moment";
import { notification } from "../components/notification";

export const fetchWithDelayPlateId = (payload) => {

    // API URL
    // const url = "http://localhost:8080/api/plate/show";
    const url = "https://park.mdt.mobi/backend/api/plate/show";

    // POST request options
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        },
        body: JSON.stringify(payload)
    };
    // Fetch function
    fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
            if (data === false) {
                notification.errorNotExistAlert()
            } else {
                if (data.length === 0) {
                    notification.errorAlert()
                } else {
                    let start_date = data[0].start_date;
                    let end_date = data[0].end_date;

                    let formatDate = Moment().format('YYYY-MM-DD');
                    let todayDate = formatDate;

                    if (start_date === end_date && start_date === todayDate) {
                        notification.successAlert(start_date, end_date, todayDate)
                    } else {
                        if ((start_date <= todayDate && todayDate <= end_date)) {
                            notification.successAlert(start_date, end_date, todayDate)
                        } else {
                            notification.errorAlert()
                        }
                    }
                }
            }
        })
        .catch((error) => {
            console.log(error);
        })
}