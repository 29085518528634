// ReactJS Bootstrap Components
import Toast from 'react-bootstrap/Toast';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup'
import ToggleButton from 'react-bootstrap/ToggleButton'
import CloseButton from 'react-bootstrap/CloseButton'
import DropdownButton from 'react-bootstrap/DropdownButton'
import SplitButton from 'react-bootstrap/SplitButton'
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'
import Feedback from 'react-bootstrap/Feedback'
import Alert from 'react-bootstrap/Alert'
import Accordion from 'react-bootstrap/Accordion'
import Badge from 'react-bootstrap/Badge'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import Card from 'react-bootstrap/Card'
import FigureImage from 'react-bootstrap/FigureImage'
import Image from 'react-bootstrap/Image'
import ListGroup from 'react-bootstrap/ListGroup'
import Modal from 'react-bootstrap/Modal'
import ModalDialog from 'react-bootstrap/ModalDialog'
import ModalHeader from 'react-bootstrap/ModalHeader'
import ModalTitle from 'react-bootstrap/ModalTitle'
import ModalBody from 'react-bootstrap/ModalBody'
import ModalFooter from 'react-bootstrap/ModalFooter'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import Overlay from 'react-bootstrap/Overlay'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Collapse from 'react-bootstrap/Collapse'
import Popover from 'react-bootstrap/Popover'
import PopoverBody from 'react-bootstrap/PopoverBody'
import PopoverHeader from 'react-bootstrap/PopoverHeader'
import Tooltip from 'react-bootstrap/Tooltip'

const Bootstrap = { Toast, Container, Button, ToggleButtonGroup, ToggleButton, CloseButton, Dropdown,
                        DropdownButton, SplitButton, Form, Feedback, Alert, Accordion, Badge, Breadcrumb,
                            Card, FigureImage, Image, ListGroup, Modal, ModalDialog, ModalHeader, ModalTitle,
                                ModalBody, ModalFooter, Table, Spinner, Overlay, ProgressBar, Collapse, Popover,  
                                    PopoverBody, PopoverHeader, Tooltip };

export default Bootstrap;