import React from "react";
import {
  // BallTriangle,
  // Bars,
  // Circles,
  // CirclesWithBar,
  // Grid,
  // LineWave,
  // MutatingDots,
  // Oval,
  // Puff,
  // RevolvingDot,
  // Rings,
  // RotatingSquare,
  // TailSpin,
  // ThreeCircles,
  ThreeDots,
  // Watch
} from "react-loader-spinner";
import "./index.css";

const Spinner = () => {
  return (
    <div className="spinner">
      <ThreeDots type="ThreeDots" color="#2BAD60" height="100" width="100" />
    </div>
  );
};

export default Spinner;

