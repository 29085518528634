import Moment from "moment";
import Swal from "sweetalert2";

const successAlert = (start_date, end_date, todayDate) => {
    if (start_date === end_date && start_date === todayDate) {
        return Swal.fire({
            toast: false,
            text: 'Stationnement autorisé ce jour: ' + Moment(start_date).format('DD-MM-YYYY'),
            icon: 'success'
        });
    } else {
        if ((start_date <= todayDate && todayDate <= end_date)) {
            return Swal.fire({
                toast: false,
                text: 'Stationnement autorisé du ' + Moment(start_date).format('DD-MM-YYYY') + ' au ' + Moment(end_date).format('DD-MM-YYYY'),
                icon: 'success'
            });
        }
    }
}

const errorAlert = () => {
    return Swal.fire({
        toast: false,
        text: 'Stationnement non autorisé.',
        icon: 'error'
    });
}


const errorNotExistAlert = () => {
    Swal.fire({
        toast: false,
        text: 'QRCode non existant dans la base de données.',
        icon: 'error'
    });
}

export const notification = {
    successAlert,
    errorAlert,
    errorNotExistAlert
};