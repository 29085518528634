import React from 'react';
import QrcodePlugin from './components/qrcode-plugin/index.js'
import Bootstrap from './Bootstrap'
import { postAPI } from "./api/postAPI"
import Spinner from './components/spinner'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            decodedResults: [],
            idQRCodes: [],
            loading: false
        }
        this.onNewScanResult = this.onNewScanResult.bind(this);
    }

    onNewScanResult(decodedText, decodedResult) {
        // Declare variable for results
        let decodedResults = this.state.decodedResults;

        // Insert results into array
        decodedResults.push(decodedResult);

        // Get qrcode id
        let idQRCode = decodedResults[0]['result']['text'];

        // Call fetch function
        postAPI.fetchPlateId({ id: idQRCode })
    }

    render() {
        return (
            <div className="App">
                {this.state.loading ? <Spinner /> : null}
                <div className='App-section'>
                    <Bootstrap.Card>
                        <Bootstrap.Card.Body>
                            <div id='qrcode-plugin'>
                                <QrcodePlugin
                                    fps={1}
                                    qrbox={200}
                                    disableFlip={false}
                                    qrCodeSuccessCallback={this.onNewScanResult}
                                />
                            </div>
                        </Bootstrap.Card.Body>
                    </Bootstrap.Card>
                </div>
            </div>
        );
    }
}

export default App;
